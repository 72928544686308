'use strict';

angular.module('enervexSalesappApp').controller('SystemTaskCtrl', function($scope, SystemTask, $stateParams, Flash, $state, _, Application, Util, $rootScope) {
	$scope.errors = {};
	$scope.ctx = {
		formChanged: false,
		id: $stateParams.id
	}
	$scope.itemCodeDisabled = true
	$scope.codeWarning = Util.setCodeWarning()

	$scope.types = [
		'syncNav',
		'updateQuotePrice',
		'checkProductDuplicates'
	]
	$scope.disableItemCode = function(){
		if ($scope.itemCodeDisabled === true) {
			$scope.itemCodeDisabled = false
		} else {
			$scope.itemCodeDisabled = true
		}
	}

	if ($stateParams.id == "new") {
		$scope.item = {
		}
		$scope.itemCodeDisabled = false
		fetchDependencies()
	} else {
		SystemTask.get({
			id: $stateParams.id
		}).$promise.then(function(item) {
			$scope.item = item;
			$scope.stats = JSON.stringify(item.stats)
			console.log("stats", $scope.stats)
			fetchDependencies()
		})
	}
	function fetchDependencies() {
	}

	$scope.save = function(form) {
		$scope.submitted = true;
		if (form.$valid) {
			Flash.clear();
			var payload = _.clone($scope.item);
			payload = _.omit(payload, "__v");
			if (!$scope.item._id) {
				SystemTask.save(payload).$promise.then(function(result) {
					
					$scope.success = true;
					Flash.create('success', '<strong>Success!</strong> Successfully created SystemTask '+result.taskNumber, false, {}, true);
					$state.go("system-tasks", {
						id: result._id,
					}, {
						inherit: true,
						reload: false
					})
				}).catch(function(err) {
					
					$scope.errors = {};
					$scope.errors.other = 'Problem saving';
					var message = (err.data && err.data.message) || "There was an error saving";
					Flash.create('danger', '<strong>Error!</strong> ' + message, false, {}, true);
				})
			} else {
				SystemTask.update({
					id: $scope.item._id
				}, payload).$promise.then(function(result) {
					
					$scope.success = true;
					Flash.create('success', '<strong>Success!</strong> Successfully updated SystemTask.', false, {}, true);
				}).catch(function(err) {
					
					$scope.errors = {};
					$scope.errors.other = 'Problem saving';
					var message = (err.data && err.data.message) || "There was an error saving";
					Flash.create('danger', '<strong>Error!</strong> ' + message, false, {}, true);
				})
			}
		} else {
			
			$scope.errors = {};
			$scope.errors.other = 'Problem saving';
			var message = "Please fix validation errors";
			Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
		}
	};
});
